/**
 * Created by osirvent on 20/07/2016.
 */
angular
    .module('annexaApp')
    .constant('tramForms', {
        newdossier: [
            {
                key: 'subject',
                type: 'annexaInputRow',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'global.literals.subject'
                },
                data: {
                    row: true,
                    colClass: ' col-sm-12',
                    labelClass: 'label-strong'
                }
            },
            {
                key: 'classification',
                type: 'annexaSelectRow',
                templateOptions: {
                    required: true,
                    label: 'global.literals.classification',
                    optionsAttr: 'bs-options',
                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                    valueProp: 'id',
                    labelProp: 'language1',
                    placeholder: '',
                    options: []
                },
                data: {
                    informed: true,
                    row: true,
                    colClass: ' col-sm-12',
                    labelClass: 'label-strong'
                }
            }
        ]
    });