
/**
 * Created by osirvent on 14/07/2016.
 */
angular
    .module('annexaApp')
    .factory('TramFactory',['$state', '$q', '$http', '$rootScope', 'Language', '$filter', 'AnnexaFormlyFactory', 'globalModals', 'HelperService', '$translate', 'ErrorFactory', 'dialogs', 'DccumentsFactory', 'ABMShapesFactory', 'RestService', 'SignLocalFactory', 'GlobalDataFactory', 'RegFactory', 'AnnexaModalFactory', 'AnnexaBoxDossierFactory', 'CommonService', 'DialogsFactory',
        function ($state, $q, $http, $rootScope, Language, $filter, AnnexaFormlyFactory, globalModals, HelperService, $translate, ErrorFactory, dialogs, DccumentsFactory, ABMShapesFactory, RestService, SignLocalFactory, GlobalDataFactory, RegFactory, AnnexaModalFactory, AnnexaBoxDossierFactory, CommonService, DialogsFactory) {
            var factory = {};

            factory.startedTramTemplateResponse = false;
        	factory.endedTramTemplateResponse = false;
        	$rootScope.$on('ModifyTramTemplatesGlobalDataCache', function(event,args){
        		var callTimeOut = function(step){
        			//setTimeout(function() {
        				if(factory.endedTramTemplateResponse || step > 30){
	        				if(factory.TramTemplates > 0){
	                    		factory.TramTemplates = $linq(GlobalDataFactory.publictramtemplates).union(factory.TramTemplates,"(x,y) => x.id == y.id").toArray();
	                    	}else{
	                    		factory.TramTemplates = angular.copy(GlobalDataFactory.publictramtemplates);
	                    	}
        				}else{
        					callTimeOut(step+1);
        				}
    				//}, 1000);
        		}
        		if(factory.startedTramTemplateResponse){
        			//if(!factory.endedTramTemplateResponse){
        				callTimeOut(1);
        			//}
        		}
        	});
            
            factory.TramTemplates = [];
            factory.Register = undefined;
            factory.Diligence = undefined;
            factory.Dossier = undefined;
            factory.Procedure = undefined;
            factory.expireDate = undefined;
            factory.initialTransaction = undefined;
			factory.initialTransactioTypeCustomFields = undefined;
            factory.proposalsDossierTransaction = [];
			factory.proposalsDecree = []; 
            factory.inspectionProposalsDossierTransaction = [];
            factory.procedureStartProfiles = [];
            factory.procedureTramitationProfiles = [];
            factory.dossierTransactionProcessProfiles = [];
            factory.dossierOperationsAndInvoices = [];
            factory.procedureOperationTypes = [];
            factory.userAccountInstances = [];
            factory.accountingReferences = [];
            factory.archiveAuditInfo = [];
            factory.rightAccess = {};
			factory.rightAccesses = [];
            factory.isArchivedDossier = false;
            factory.canUpdateDossier = false;
			factory.emgdeDossierMetadata = undefined;
			factory.byNumberCanUpdate = undefined;
			factory.byNumberId = undefined;
			factory.byNumberDtId = undefined;
			factory.byNumberRightAccess = undefined;
		
		    factory.getTramTemplates = function (dossierId) {
            	factory.startedTramTemplateResponse = true;
            	factory.endedTramTemplateResponse = false;
                return $http({
                    url: './api/tram/template/procedure',
                    method: 'GET',
                    params: {dossierId: dossierId}
                }).then(function (data) {
                	if(data && data.data && data.data.length > 0){
                		var tempProd = JSOG.decode(data.data);
                		factory.TramTemplates = $linq(GlobalDataFactory.publictramtemplates).union(tempProd,"(x,y) => x.id == y.id").toArray();;
                	}else{
                		factory.TramTemplates = angular.copy(GlobalDataFactory.publictramtemplates);
                	}
                	factory.endedTramTemplateResponse = true;
                }).catch(function (error) {
                	factory.endedTramTemplateResponse = true;
                });
            }

            factory.cancelDossier = function (reason) {
                var deferred = $q.defer();

                $http({
                    url: './api/tram/dossier',
                    method: 'DELETE',
                    params: {dossierId: factory.Dossier.id, reason: reason}
                }).then(function (data) {
                    deferred.resolve(data);
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            }

            factory.fromTemplate = function (transaction, tramtemplate, document_title, section, profile, thirdsSelected, profiles, documentType, customFields, archiveClassification, relatedDocument) {
                var deferred = $q.defer();

                var send = {};
                send.transaction = JSOG.encode(transaction);
                send.tramTemplate = tramtemplate;
                send.user = JSOG.encode($rootScope.LoggedUser);
                send.langColumn = Language.getActiveColumn();
                send.title = document_title;
                send.translations = {
                    "third_relation_interested": $filter('translate')('global.literals.interested'),
                    "third_relation_representative": $filter('translate')('global.literals.representative'),
                    "yes": $filter('translate')('global.literals.yes'),
                    "no": $filter('translate')('global.literals.no')
                };
                send.section = section;
                send.profile = profile;
                send.profiles = profiles;
                send.type = documentType;
                send.customFields = customFields;
                send.archiveClassification = archiveClassification;
                if (thirdsSelected) {
                    send.thirdsSelected = JSOG.encode(thirdsSelected);
                }
                if(relatedDocument){
                	send.relatedDocument = relatedDocument;
                }
                
                $http({
                    url: './api/tram/fromtemplate',
                    method: 'POST',
                    data: send
                }).then(function (data) {
                    deferred.resolve(JSOG.decode(data.data));
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            };
            
            factory.getProcedure = function (id, entity, register, diligence) {
                factory.Register = register;
                factory.Diligence = diligence;

                var promises = [];
                promises.push($http({url: './api/tram/procedure/start', method: 'GET', params: {idProcedure: id, idEntity: entity.id, idRegisterEntry:((register && register.id)?register.id:undefined) }}));
                promises.push($http({url: './api/transactiontypes/transactionTypesCustomFieldsByProcedure/'+id, method: 'GET'}));
				return $q.all(promises).then(function(data) {
                    var decData = JSOG.decode(data[0].data);
                    factory.Dossier = undefined;
					factory.initialTransactioTypeCustomFields = undefined;
                    factory.Procedure = decData.procedure;
					factory.userDefaultEntityProfile = decData.userDefaultEntityProfile;
                    if (!factory.Procedure.archiveClassification) {
                        factory.Procedure.archiveClassification = {};
                    }
                    factory.expireDate = decData.expireDate;
                    factory.maxDays = decData.maxDays;
                    if(factory.Procedure.guided){
                        var initialTransactions = $linq(factory.Procedure.transactions)
                            .where("x => x.transaction.graphNodeType == '"+ABMShapesFactory.TI+"'")
                            .toArray();
                        if(initialTransactions && initialTransactions.length > 0){
                            factory.initialTransaction = initialTransactions[0].transaction;
                        }else{
						    factory.initialTransaction = undefined;
                        }
                    }else {
                        var initialTransactions = $linq(factory.Procedure)
                            .join(factory.Procedure.transactionTransitions, "x => x.id", "x => x.procedure.id", "(outer, inner) => inner")
                            .where("x => x.currentTransaction == null")
                            .toArray();
                        factory.initialTransaction = null;
                        if (initialTransactions.length != 0) {
                            factory.initialTransaction = initialTransactions[0].nextTransaction;
                        }else{
	    					factory.initialTransaction = undefined;
						}
                    }
					var dataAux = JSOG.decode(data[1].data);
					factory.initialTransactioTypeCustomFields = dataAux;
					
		        }).catch(function (error) {
					factory.initialTransactioTypeCustomFields = undefined;;
                });
            };

			factory.getUserAccountInstance = function(id){
				var promises = [];
                promises.push($http({url: './api/tram/userAccountInstances', method: 'GET'}));
                promises.push($http({url: './api/tram/accountingReferences/proposal', method: 'GET', params: {idProposal: id}}));
                return $q.all(promises).then(function(data) {var dos = JSOG.decode(data[0].data);
                   	factory.userAccountInstances = JSOG.decode(data[0].data);
                    factory.accountingReferences = JSOG.decode(data[1].data);
                }).catch(function() {
                    factory.userAccountInstances = [];
                    factory.accountingReferences = [];
                })
			}            

            factory.getDossier = function (id, archiveModule, rightAccess) {
        	   	factory.byNumberCanUpdate = undefined;
				factory.byNumberId = undefined;
				factory.byNumberDtId = undefined;
				factory.byNumberRightAccess = undefined;
				var entity = $rootScope.app.configuration.entity;
                factory.isArchivedDossier = ((archiveModule && archiveModule == true)?true:false);
                var promises = [];
                promises.push($http({url: './api/tram/dossier', method: 'GET', params: {idDossier: id}, headers:{"callFromAnnexa":true}}));
                promises.push($http({url: './api/tram/proposalsDossierTransaction', method: 'GET', params: {idDossier: id}}));
                promises.push($http({url: './api/tram/inspectionProposalsDossierTransaction', method: 'GET', params: {idDossier: id}}));
                promises.push($http({url: './api/tram/procedureStartProfiles', method: 'GET', params: {idDossier: id}}));
                promises.push($http({url: './api/tram/dossierTransactionProcessProfiles', method: 'GET', params: {idDossier: id}}));
                promises.push($http({url: './api/tramEMGDE/' + id+'/emgdeDossierMetadata', method: 'GET'}));
                promises.push($http({url: './api/tram/dossierOperationsAndInvoices', method: 'GET', params: {idDossier: id}}));
                promises.push($http({url: './api/tram/procedureOperationTypes', method: 'GET', params: {idDossier: id}}));
                promises.push($http({url: './api/tram/userAccountInstances', method: 'GET'}));
                promises.push($http({url: './api/tram/accountingReferences', method: 'GET', params: {idDossier: id}}));
                promises.push($http({url: './api/tram/dossier/canUpdateDossier/'+id, method: 'GET'}));
                if(archiveModule){
                	promises.push($http({url: './api/tram/archive/auditInfo/'+id, method: 'GET'}));
                }
               	promises.push($http({url: './api/tram/archive/rightAccess/'+id, method: 'GET'}));
				promises.push($http({url: './api/tram/proposalsDecree/'+id, method: 'GET'}));
                promises.push($http({url: './api/tram/procedureTramitationProfiles', method: 'GET', params: {idDossier: id}}));
                return $q.all(promises)
                    .then(function(data) {
                    	var dos = JSOG.decode(data[0].data);
                    	if(dos && $state.current && !archiveModule &&  (dos.dossierStatus == 'ARCHIVED' || dos.dossierStatus == 'REJECTED' || dos.dossierStatus == 'TRANSFER')){
                    		factory.Dossier = undefined;
			        	   	factory.byNumberCanUpdate = undefined;
							factory.byNumberId = undefined;
							factory.byNumberDtId = undefined;
							factory.byNumberRightAccess = undefined;
							factory.proposalsDossierTransaction = [];
							factory.proposalsDecree = [];
                            factory.inspectionProposalsDossierTransaction = [];
                            factory.procedureStartProfiles = [];
                            factory.procedureTramitationProfiles = [];
                            factory.dossierTransactionProcessProfiles = [];
                            factory.dossierOperationsAndInvoices = [];
                            factory.procedureOperationTypes = [];
                            factory.userAccountInstances = [];
                            factory.accountingReferences = [];
                            factory.archiveAuditInfo = [];
                            factory.rightAccess = {};
							factory.rightAccesses = [];
            				factory.canUpdateDossier = false;
							factory.emgdeDossierMetadata = undefined;
                            dialogs.error(
                                    $filter('translate')('DIALOGS_ERROR_MSG'),
                                    $filter('translate')('global.archive.errorArchiveds'),
                                    {
                                        animation: true,
                                        backdrop: 'static',
                                        keyboard: true,
                                        size: ''
                                    }
                                );
                            $state.transitionTo('annexa.dashboard');
                    	}else{
	                        factory.byNumberCanUpdate = undefined;
							factory.byNumberId = undefined;
							factory.byNumberDtId = undefined;
							factory.byNumberRightAccess = undefined;
							factory.Dossier = dos;
	                        factory.DossierArchiveClassification = factory.Dossier.archiveClassification;
	                        if (!factory.Dossier.archiveClassification) {
	                            factory.Dossier.archiveClassification = {};
	                        }
	                        if(!factory.Dossier.relatedDossiers){
	                            factory.Dossier.relatedDossiers = [];
	                        }
	                        factory.proposalsDossierTransaction = JSOG.decode(data[1].data);                        
	                        factory.inspectionProposalsDossierTransaction = JSOG.decode(data[2].data);
	                        factory.procedureStartProfiles =  JSOG.decode(data[3].data);
	                        if(factory.Dossier && factory.Dossier.procedure){
	                        	factory.Dossier.procedure.procedureStartProfiles = factory.procedureStartProfiles;
							}
	                        factory.dossierTransactionProcessProfiles = JSOG.decode(data[4].data);
							factory.emgdeDossierMetadata = JSOG.decode(data[5].data);
	                        factory.dossierOperationsAndInvoices = JSOG.decode(data[6].data);
	                        factory.procedureOperationTypes = JSOG.decode(data[7].data);
	                        factory.userAccountInstances = JSOG.decode(data[8].data);
	                        factory.accountingReferences = JSOG.decode(data[9].data);
							factory.canUpdateDossier = JSOG.decode(data[10].data);
	                        factory.secretaryProfile = GlobalDataFactory.secretaryProfile;
	                        if(archiveModule){
	                        	factory.archiveAuditInfo = JSOG.decode(data[11].data);
	                        	var rightAccessAux = JSOG.decode(data[12].data);
								if(rightAccessAux){
									factory.rightAccess = ((rightAccess && rightAccessAux.actual)?rightAccessAux.actual:{});
									factory.rightAccesses = ((rightAccessAux.rightAccesses)?rightAccessAux.rightAccesses:[]);
								}else{
									factory.rightAccess = {};
									factory.rightAccesses = [];
								}
								factory.proposalsDecree = JSOG.decode(data[13].data);
								factory.procedureTramitationProfiles =  JSOG.decode(data[14].data);
		                        if(factory.Dossier && factory.Dossier.procedure){
		                        	factory.Dossier.procedure.procedureTramitationProfiles = factory.procedureTramitationProfiles;
								}
	                        }else{
	                        	factory.archiveAuditInfo = [];
	                        	var rightAccessAux = JSOG.decode(data[11].data);
								if(rightAccessAux){
									factory.rightAccess = ((rightAccess && rightAccessAux.actual)?rightAccessAux.actual:{});
									factory.rightAccesses = ((rightAccessAux.rightAccesses)?rightAccessAux.rightAccesses:[]);
								}else{
									factory.rightAccess = {};
									factory.rightAccesses = [];
								}
								factory.proposalsDecree = JSOG.decode(data[12].data);
								factory.procedureTramitationProfiles =  JSOG.decode(data[13].data);
		                        if(factory.Dossier && factory.Dossier.procedure){
		                        	factory.Dossier.procedure.procedureTramitationProfiles = factory.procedureTramitationProfiles;
								}
	                        }
	                        
                    	}
                    }).catch(function() {
                   	   	factory.byNumberCanUpdate = undefined;
						factory.byNumberId = undefined;
						factory.byNumberDtId = undefined;
						factory.byNumberRightAccess = undefined;
						factory.Dossier = undefined;
                        factory.proposalsDossierTransaction = [];
                        factory.proposalsDecree = [];
                        factory.inspectionProposalsDossierTransaction = [];
                        factory.procedureStartProfiles = [];
                        factory.procedureTramitationProfiles = [];
                        factory.dossierTransactionProcessProfiles = [];
                        factory.dossierOperationsAndInvoices = [];
                        factory.procedureOperationTypes = [];
                        factory.userAccountInstances = [];
                        factory.accountingReferences = [];
                        factory.archiveAuditInfo = [];
                        factory.rightAccess = {};
						factory.rightAccesses = [];
            			factory.canUpdateDossier = false;
						factory.emgdeDossierMetadata = undefined;
                    })
            };
			
			factory.getDossierByNumber = function (numberDossier) {
				factory.byNumberCanUpdate = undefined;
				factory.byNumberId = undefined;
				factory.byNumberDtId = undefined;
				factory.byNumberRightAccess = undefined;
				return $http({url: './api/tram/byNumber/' + numberDossier, method: 'GET'}).then(function(data) {
					if(data && data.data){
						var result = JSOG.decode(data.data);
		        		factory.byNumberCanUpdate = ((result.canUpdate)?true:false);
						factory.byNumberId = result.dossierId;
						factory.byNumberDtId = result.dossierTransactionId;
						factory.byNumberRightAccess = ((result.seeWithRightAccess)?true:false);
						if(!factory.byNumberId){
		                	$state.go('annexa.dashboard');
		                }
					}else{
		                if(!factory.byNumberId){
		                	$state.go('annexa.dashboard');
		                }
					}
	            }).catch(function (error) {
	           	   	factory.byNumberCanUpdate = undefined;
					factory.byNumberId = undefined;
					factory.byNumberDtId = undefined;
					factory.byNumberRightAccess = undefined;
					$state.go('annexa.dashboard');
					console.error(error);
	            });
	        }

            factory.updateDossier = function (dossier, update) {
                var deferred = $q.defer();
                var dossierAux = angular.copy(dossier);
                if(!update){
                	dossierAux.archiveClassification = ((factory.DossierArchiveClassification)?factory.DossierArchiveClassification : {});
                }
                _.forEach(dossierAux.thirds, function (value, key) {
                    if (value.representated) {
                        delete dossierAux.thirds[key].representated.label;
                    }
                });

                $http({
                    url: './api/tram/dossier',
                    method: 'PUT',
                    data: JSOG.encode(dossierAux)
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };

            factory.endDossier = function (transaction) {
                var deferred = $q.defer();
                var send = {};
                send.transaction = JSOG.encode(transaction);

                $http({
                    url: './api/tram/endDossier',
                    method: 'POST',
                    data: send
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };

            factory.completeDossierTransaction = function (transaction, profile, puser, transactionNoGuiadaList, completeTransaction) {
                var deferred = $q.defer();

                var currentLanguage = $translate.use();
                var lang1 = Language.getByColumn('language1');
                var lang2 = Language.getByColumn('language2');
                var lang3 = Language.getByColumn('language3');
                var trans = {}
                $translate.use(lang1).then(function (translation) {
                    trans.tram1 = $translate.instant('global.literals.tramitation');
                    trans.puser1 = $translate.instant('global.tram.literals.pending_user');

                    $translate.use(lang2).then(function (translation) {
                        trans.tram2 = $translate.instant('global.literals.tramitation');
                        trans.puser2 = $translate.instant('global.tram.literals.pending_user');

                        $translate.use(lang3).then(function (translation) {
                            trans.tram3 = $translate.instant('global.literals.tramitation');
                            trans.puser3 = $translate.instant('global.tram.literals.pending_user');

                            $translate.use(currentLanguage);

                            var send = {};

                            send.transaction = JSOG.encode(transaction);
                            if (profile && profile.id) {
                                send.profile = JSOG.encode(profile);
                            }

                            if (transactionNoGuiadaList && transactionNoGuiadaList.length > 0) {
                            	var tngl = angular.copy(transactionNoGuiadaList);
                                _.forEach(tngl, function(value){
                                    if(value.selectedResponsible && value.selectedResponsible.id){
                                        value.selectedResponsible = {id:value.selectedResponsible.id};
                                    }
                                    if(value.expirationType) {
                                    	value.expirationType = value.expirationType.id;
                                    }
                                });
                                send.transactionNoGuiadaList = JSOG.encode(tngl);
                            }
                            if (completeTransaction) {
                                send.completeTransaction = completeTransaction;
                            }
                            send.translations = trans;
                            send.pendingUser = puser;

                            $http({
                                url: './api/tram/completeDossierTransaction',
                                method: 'POST',
                                data: send
                            }).then(function (data) {
                                if(data && data.status && (data.status == 500 || data.status == 406 || data.status == 403 || data.status == 404)){
                                    deferred.reject(data);
                                }
                                else{
                                    deferred.resolve(data.data);
                                }
                            }).catch(function (error) {
                                deferred.reject(error);
                            })
                        }).catch(function (error) {
                            $translate.use(currentLanguage);
                            deferred.reject(error);
                        })
                    }).catch(function (error) {
                        $translate.use(currentLanguage);
                        deferred.reject(error);
                    })
                }).catch(function (error) {
                    $translate.use(currentLanguage);
                    deferred.reject(error);
                })

                return deferred.promise;
            };

            factory.completeDossierTransactionGuiat = function (transaction, nextDossierTransactionInfo) {
                var deferred = $q.defer();
                var send = {};
                send.transaction = transaction;
                send.nextDossierTransactionInfo = nextDossierTransactionInfo;

                $http({
                    url: './api/tram/completeDossierTransactionGuiat',
                    method: 'POST',
                    data: JSOG.encode(send)
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };
                
            factory.newDossier = function (dossier, cfvalues, user, thirds, registerEntryInput, dossiers, registerEntryOutput, transactionType, documents, responsibleTransactionUser, profiles, addresses, cfDossierValues) {
                var deferred = $q.defer();
                var send = {};
                send.dossier = dossier;
                send.cfValues = cfvalues;
				send.cfDossierValues = cfDossierValues;
                send.user = user;
                if (transactionType) {
                    send.transactionType = {id: transactionType};
                }
                if (responsibleTransactionUser) {
                    send.responsibleTransactionUser = {id: responsibleTransactionUser};
                }
                if(documents && documents.length > 0){
                    send.documents = [];
                    angular.forEach(documents, function (value, key) {
                        value.document = {id: value.document.id};
                        send.documents.push(value);
                    });
                }
                if (factory.Register) {
                    var fixed_thirds = []
                    angular.forEach(thirds, function (value, key) {
                        value.third = {id: value.third.id};
                        if(value.representated && value.representated.id){
                            value.representated = {id: value.representated.id};
                        }
                        fixed_thirds.push(value);
                    });
                    send.dossierThirds = fixed_thirds;

                    var fixed_ri = []
                    angular.forEach(registerEntryInput, function (value, key) {
                        fixed_ri.push({id: value.id});
                    });
                    send.registerEntryInput = fixed_ri;

                    var fixed_ro = []
                    angular.forEach(registerEntryOutput, function (value, key) {
                        fixed_ro.push({id: value.id});
                    });
                    send.registerEntryOutput = fixed_ro;

                    send.register = {id: factory.Register.id};
                } else {
                    send.dossierThirds = thirds;
                    send.registerEntryInput = registerEntryInput;
                    send.registerEntryOutput = registerEntryOutput;
                    send.register = {id: -1};
                }
                if (factory.Diligence) {
                    send.diligence = {id: factory.Diligence.id};
                }
                if(addresses && addresses.length > 0){
                    send.addresses = [];
                    angular.forEach(addresses, function (value, key) {
                        value.address = {id: value.address.id};
                        send.addresses.push(value);
                    });
                }
                send.dossiers = dossiers;

                $http({
                    url: './api/tram/dossier',
                    method: 'POST',
                    data: JSOG.encode(send)
                }).then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            };

            factory.deleteThird = function (dossier, thirdId) {
                var deferred = $q.defer();

                $http({
                    url: './api/tram/deleteThirdDossier',
                    method: 'POST',
                    params: {idDossier: dossier, idThird: thirdId}
                }).then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            };

            factory.deleteRegister = function (dossier, registerId, registerType) {
                var deferred = $q.defer();

                $http({
                    url: './api/tram/deleteRelatedEntriesDossier',
                    method: 'POST',
                    params: {idDossier: dossier, idRelatedEntry: registerId, relatedEntryType: registerType}
                }).then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            };

            factory.deleteDocument = function (transaction, documentId) {
                var deferred = $q.defer();

                $http({
                    url: './api/tram/deleteDocumentsDossierTransaction',
                    method: 'POST',
                    params: {idDossierTransaction: transaction, idDocument: documentId}
                }).then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            };
            
            factory.deleteMultipleDocuments = function (documentsToDelete) {
                var deferred = $q.defer();

                $http({
                    url: './api/tram/deleteMultipleDocumentsDossierTransaction',
                    method: 'POST',
                    data: JSOG.encode(documentsToDelete)
                }).then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            };

            factory.deleteTask = function (transaction, taskId) {
                var deferred = $q.defer();

                $http({
                    url: './api/tram/deleteTasksDossierTransaction',
                    method: 'POST',
                    params: {idDossierTransaction: transaction, idTask: taskId}
                }).then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            };

            factory.updateDossierTransaction = function (transaction) {
                var deferred = $q.defer();
                if(!transaction.dossier && transaction.dossierId){
                    transaction.dossier = {id:transaction.dossierId};
                }
                $http({
                    url: './api/tram/dossierTransaction',
                    method: 'PUT',
                    data: JSOG.encode(transaction)
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };

            factory.deleteDossierTransaction = function (idTransaction, transactionLists, reason, dossierTransactionToReopen) {
                var deferred = $q.defer();
                var send = {};
                send.idDossierTransaction = idTransaction;
                send.reason = reason;
                send.dossierTransactionToReopen = dossierTransactionToReopen;
                if (transactionLists && transactionLists.length > 0) {
                	var tlist = angular.copy(transactionLists);
                    _.forEach( tlist, function(value){
                    	if(value.selectedResponsible && value.selectedResponsible.id){
                            value.selectedResponsible = {id:value.selectedResponsible.id};
                        }
                        if(value.expirationType) {
                            value.expirationType = value.expirationType.id;
                        }
                        if(value.expirationType) {
                        	value.expirationType = value.expirationType.id;
                        }
                    });
                    send.transactionNoGuiadaList = JSOG.encode(tlist);
                }
                $http({
                    url: './api/tram/deleteDossierTransaction',
                    method: 'POST',
                    data: send
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };

            factory.updateDossierTransactionCustomField = function (field) {
                var deferred = $q.defer();

                $http({
                    url: './api/tram/dossierTransactionCustomField',
                    method: 'PUT',
                    data: JSOG.encode(field)
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };

			factory.updateDossierCustomField = function (field) {
                var deferred = $q.defer();

                $http({
                    url: './api/tram/dossierCustomField',
                    method: 'PUT',
                    data: JSOG.encode(field)
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };

            factory.updateRelationTypeDossierTransactionDocument = function(dossierTransationDocumentId, relationType){
            	var deferred = $q.defer();

                $http({
                    url: './api/tram/dossierTransactionDocument/' + dossierTransationDocumentId + '/relationType/' + relationType,
                    method: 'PUT'
                }).then(function (data) {
                    deferred.resolve(JSOG.decode(data.data));
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            }
            
            factory.updatePaperBinDossierTransactionDocument = function(dossierTransationDocumentId, toPaperBin){
            	var deferred = $q.defer();

                $http({
                    url: './api/tram/dossierTransactionDocument/' + dossierTransationDocumentId + '/paperBin/' + toPaperBin,
                    method: 'PUT'
                }).then(function (data) {
                    deferred.resolve(JSOG.decode(data.data));
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            }
               
            factory.actionFunction = function (transaction, documentId, fileInfo, code, action, $scope, object) {
                var deferred = $q.defer();

                if (action == 'DELETE') {
                    //Delete document
                    $http({
                        url: './api/tram/deleteDocumentsDossierTransaction',
                        method: 'POST',
                        params: {idDossierTransaction: transaction.id, idDocument: documentId}
                    }).then(function (data) {
                        deferred.resolve(data.data);
                    }).catch(function (error) {
                        deferred.reject(error);
                    });

                } else if (action == 'DOWNLOAD') {

                    $scope.showLoadingdiv = true;
                    var href = "./api/repodocs/downloaddoc?id=";

                    if (fileInfo.pdf) {
                        if (fileInfo.idPdf && fileInfo.idPdf != 'null' && fileInfo.idPdf != 'undefined') {
                            href = href + fileInfo.idPdf + '&mimetype=' + fileInfo.fileTypePDF;
                        } else {
                            href = href + fileInfo.idDocumentManager + '&mimetype=application/pdf&convertPDF=true';
                        }
                    } else {
                        href = href + fileInfo.idDocumentManager + '&mimetype=' + fileInfo.fileType;
                    }
                    $http.get(href, {responseType: 'arraybuffer'})
                        .success(function (data, status, headers) {
                            headers = headers();

                            var contentType = headers['content-type'];
                            var file = new Blob([data], {type: contentType});
                            var fileName = fileInfo.name;
                            if (fileInfo.pdf) {
                                if (fileInfo.name.endsWith('.pdf')) {
                                    fileName = fileInfo.name;
                                } else {
                                    fileName = fileInfo.name + ".pdf";
                                }
                            }
                            if (window.navigator.msSaveOrOpenBlob) {
                                try {
                                    window.navigator.msSaveOrOpenBlob(file, fileName);
                                    $scope.showLoadingdiv = false;
                                } catch (e) {
                                    console.log(e.message);
                                    $scope.showLoadingdiv = false;
                                }
                            } else {
                                var linkElement = document.createElement('a');
                                try {
                                    var url = window.URL.createObjectURL(file);
                                    linkElement.setAttribute('href', url);
                                    linkElement.setAttribute("download", fileName);
                                    var clickEvent = new MouseEvent("click", {
                                        "view": window,
                                        "bubbles": true,
                                        "cancelable": false
                                    });
                                    $scope.showLoadingdiv = false;
                                    linkElement.dispatchEvent(clickEvent);
                                    deferred.resolve(true);
                                } catch (ex) {
                                    $scope.showLoadingdiv = false;
                                    console.log(ex);
                                }
                            }
                        }).error(function (error) {
                        deferred.reject(error);
                    });
                } else if (action == 'TOSIGN') {
                    SignLocalFactory.sendToSignModal(undefined, [object]);
                    $scope.$on('SendToSignModalFinished', function (event, args) {
                        deferred.resolve(args.data[0]);
                    });
                } else if (action == 'NEW_OUTPUT_REG') {
                    var retData = [];
                    var documentThird = undefined;
                    if(transaction && transaction.documents){
                        var docActual = $linq(transaction.documents).singleOrDefault(undefined, "x => x.document.id == "+documentId);
                        if(docActual && docActual.dossierThird){
                            retData.push(docActual.dossierThird);
                        }
                    }
                    if(retData && retData.length > 0) {
                        deferred.resolve(retData);
                    }else{
                        this.editReceiver(factory.Dossier.thirds, Language.getActiveColumn(), retData).then(function (data) {
                            deferred.resolve(data);
                        }).catch(function (error) {
                            deferred.reject(error);
                        });
                    }
                } else if (action == 'COMPLETE') {
                    if(Array.isArray(documentId)) {
                        DccumentsFactory.definitiveDocuments(documentId).then(function (data) {
                            if (data) {
                                deferred.resolve(data);
                            } else {
                                deferred.resolve("OK");
                            }
                        }).catch(function (error) {
                            deferred.reject(error);
                        });
                    }else{
                        DccumentsFactory.definitiveDocument({id: object.document.id}).then(function (data) {
                            if (data) {
                                object.document = data;
                                deferred.resolve(object.document);
                            } else {
                                deferred.resolve("OK");
                            }
                        }).catch(function (error) {
                            deferred.reject(error);
                        });
                    }
                }

                return deferred.promise;
            };

            factory.editReceiver = function (receiver, langCol, retData) {

                var deferred = $q.defer();
                var modal = angular.copy(globalModals.receiverEdit);
                modal.model = {};
                modal.model.modal_body = {};
                modal.options = {};

                try {
                    var receivers = angular.copy(receiver);

                    // Carregar el array amb els Destinataris actuals
                    var destinataris = [];
                    var destFinal = [];
                    angular.forEach(receivers, function (value, key) {
                        var destinatariType = '';
                        if (value.interested) {
                            destinatariType = $filter('translate')('global.literals.interested');
                        } else {
                            var representated = '';
                            if (value.representated && value.representated.identificationDocument) {
                                if (value.representated.name) {
                                    representated = '(' + value.representated.name + ' ' + value.representated.surename1 + ((value.representated.surename2)?' '+value.representated.surename2:'')+ ' (' + value.representated.identificationDocument + ')';
                                } else {
                                    representated = '(' + value.representated.corporateName + ' (' + value.representated.identificationDocument + ')';
                                }
                            }
                            destinatariType = $filter('translate')('global.literals.representative', {representated: representated});
                        }
                        var thirdName = '';
                        if (value.third.thirdType == 'PHISICAL') {
                            thirdName = value.third.name + ' ' + value.third.surename1+ ((value.third.surename2)?' '+value.third.surename2:'');
                        } else {
                            thirdName = value.third.corporateName;
                        }

                        destinataris.push({
                            'value': value.third.id,
                            'label': thirdName,
                            'labelHtml': destinatariType
                        });
                    });

                    modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.options = destinataris;

                    var submitFunction = function () {
                        angular.forEach(receivers, function (data) {
                            if (modal.annexaFormly.model.modal_body['editReceiver_' + data.third.id] == true) {
                                destFinal.push(data);
                            }
                        })
                        modal.close();
                        deferred.resolve(destFinal);
                    };
                    AnnexaFormlyFactory.showModal("modalEditReceiver", modal, submitFunction, false);
                }
                catch (error) {
                    deferred.reject(error);
                }
                return deferred.promise;
            };

            factory.getDefaultOutRegChannel = function () {
                return $http({
                    url: './api/tram/defaultOutRegChannel',
                    method: 'GET'
                }).then(function (data) {
                    return data.data;
                }).catch(function (error) {
                    var a = 0;
                });
            };

            factory.modifyFavorite = function (idDossierTransaction, idUser) {
                var deferred = $q.defer();
                $http({
                    url: './api/tram/dossierTransactionModifyFavorite',
                    method: 'POST',
                    params: {idDossierTransaction: idDossierTransaction, idUser: idUser}
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            }

            factory.generateFoliate = function () {
                if (factory.Dossier) {
                    var modal = angular.copy(globalModals.generateFoliate);
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = {};
                    modal.annexaFormly.options = {};
                    modal.annexaFormly.options.formState = {readOnly: false};
                    modal.annexaFormly.model.modal_body.documents = {};
                    var selectableDocuments = [];
                    _.forEach(factory.Dossier.dossierTransactions, function (value, key) {
                        if (value.documents) {
                            _.forEach(value.documents, function (value2, key2) {
                            	var type = $linq(GlobalDataFactory.documentRelationTypes).firstOrDefault({ id: 'DOCUMENT', language1: $filter('translate')('global.enums.documentRelationTypes.document'), language2: $filter('translate')('global.enums.documentRelationTypes.document'), language3: 'DOCUMENT' }, "x => x.id == '"+value2.relationType+"'");
                            	selectableDocuments.push({ id: value2.id, value: value2.document.name, type:type.id, typeName:$filter('translate')(type[Language.getActiveColumn()]), order:value2.document.createdDate});
                                modal.annexaFormly.model.modal_body.documents[value2.id] = false;
                            });
                        }
                    });
                    modal.annexaFormly.fields[0].fieldGroup[4].templateOptions.options = $linq(selectableDocuments).orderBy("x => x.order").toArray();
                    modal.alerts = [];
                    var submitFunction = function () {
                        var model = this.annexaFormly.model.modal_body;
                        var self = this;
                        self.alerts.length = 0;
                        var send = {};
                        send.dossier = JSOG.encode(angular.copy(factory.Dossier));
                        if (model.documents) {
                            var docs = [];
                            angular.forEach(model.documents, function (value, key) {
                                if (value) {
                                    var docToPush  = $linq(selectableDocuments).where("x => x.id == "+key).toArray();
                                    if(docToPush && docToPush.length > 0 && docToPush[0].id) {
                                        docs.push({id:docToPush[0].id});
                                    }
                                }
                            });
                            docs = $linq(docs).orderBy("x => x.id").toArray();
                            send.documents = JSOG.encode(docs);
                        }
                        send.languageColumn = Language.getActiveColumn();
                        if(!docs || docs.length == 0){
                            var errorTranslate = {msg: ErrorFactory.getErrorMessage('dossier', 'foliate', {message: 'Not found documents'})};
                            self.alerts.push({msg: errorTranslate.msg});
                        }else {
                            $http({
                                url: './api/tram/foliate',
                                method: 'POST',
                                data: send,
                                responseType: 'arraybuffer',
                                cache: false
                            }).then(function (data, status, headers) {
                                var contentType = data.headers('content-type');
                                if (!contentType) {
                                    contentType = 'application/zip';
                                }
                                var name = data.headers('content-disposition');
                                if (name) {
                                    name = name.split(";");
                                    if (name && name.length > 1) {
                                        name = name[1].trim().split("=");
                                        if (name && name.length > 1) {
                                            name = name[1];
                                            name = name.replace(/"/g, '');
                                        } else {
                                            name = "foliateExp.zip";
                                        }
                                    } else {
                                        name = "foliateExp.zip";
                                    }
                                } else {
                                    name = "foliateExp.zip";
                                }
                                var file = new Blob([data.data], {type: contentType});
                                if (window.navigator.msSaveOrOpenBlob) {
                                    try {
                                        window.navigator.msSaveOrOpenBlob(file, name);
                                    } catch (e) {
                                        console.log(e.message);
                                    }
                                } else {

                                    var linkElement = document.createElement('a');
                                    try {
                                        var url = window.URL.createObjectURL(file);
                                        linkElement.setAttribute('href', url);
                                        linkElement.setAttribute("download", name);
                                        var clickEvent = new MouseEvent("click", {
                                            "view": window,
                                            "bubbles": true,
                                            "cancelable": false
                                        });
                                        linkElement.dispatchEvent(clickEvent);
                                    } catch (ex) {
                                        console.log(ex);
                                    }
                                }
                                self.close();
                            }).catch(function (error) {
                                var errorAux = ((error.data)?error.data:undefined);
								if(errorAux){
									errorAux = String.fromCharCode.apply(null, new Uint8Array(errorAux));
									if(errorAux){
										errorAux = angular.fromJson(errorAux);
									}else{
										errorAux = undefined;
									}
								}else{
									errorAux = undefined;
								}
								if(errorAux && errorAux.code && errorAux.code.toUpperCase().endsWith('Template Exception'.toUpperCase())){
									self.alerts.push({msg: 'global.tram.errors.processTemplate', msgAux:((errorAux.message)?errorAux.message : undefined), show:function(alert){if(alert){alert.showAux = ((alert.showAux)?false:true)}}, showAux:false});
								}else{
									var errorTranslate = {msg: ErrorFactory.getErrorMessage('dossier', 'foliate', error.data)};
                                	self.alerts.push({msg: errorTranslate.msg});
								}
                            });
                        }
                    };
                    AnnexaFormlyFactory.showModal('modalFoliateTram', modal, submitFunction, false, undefined, 'global.literals.generate_foliate');
                } else {
                    //ERROR no hi ha dossier
                }
            };

            factory.editReceiverAndDocuments = function (receivers, documents, langCol, retData) {
                var deferred = $q.defer();
                try {
                    var selfNewNotification = this;
                    var data = {
                        row: true,
                        colClass: 'col-sm-12',
                        labelClass: 'label-strong',
                        extralabel: true,
                        clear: function ($event, model, key, $select) {
                            $event.stopPropagation();
                            model[key] = undefined;
                            if ($select) {
                                $select.selected = undefined;
                                $select.search = undefined;
                            }
                        }
                    };

                    var modal = angular.copy(globalModals.editReceiverAndDocuments);
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = {};
                    modal.annexaFormly.options = {};
                    modal.annexaFormly.options.formState = {readOnly: false};
                    modal.extra = this;
                    var form = new AnnexaFormly();
                    if (documents) {
                        var i = 0;
                        angular.forEach(documents, function (value, key) {
                            var field = form.createField(
                                'documents' + i,
                                'annexaRadioCheckboxRow',
                                'col-sm-12',
                                new AnnexaFormlyFieldSelectTemplateOptions(
                                    '',
                                    'value',
                                    'label',
                                    [{value: value.id, label: value.name}],
                                    false,
                                    'checkbox'
                                ),
                                data
                            );
                            modal.annexaFormly.fields[0].fieldGroup[1].fieldGroup.push(field);
                            i++;
                        });
                        modal.annexaFormly.fields[0].fieldGroup[1].fieldGroup[0].templateOptions.label = $filter('translate')('global.literals.documents');
                    }
                    if (receivers) {
                        var i = 0;
                        angular.forEach(receivers, function (value, key) {
                            var name = ((value.third.name) ? value.third.name + " " : "") + ((value.third.surename1) ? value.third.surename1 + " " : "") + ((value.third.surename2) ? value.third.surename2 + " " : "") + ((value.third.corporateName) ? value.third.corporateName : "");
                            var destinatariType = '';
                            if (value.interested) {
                                destinatariType = $filter('translate')('global.literals.interested');
                            } else {
                                var representated = '';
                                if (value.representated && value.representated.identificationDocument) {
                                    if (value.representated.name) {
                                        representated = '(' + value.representated.name + ' ' + value.representated.surename1 + ((value.representated.surename2)? ' '+value.representated.surename2:'')+ ' (' + value.representated.identificationDocument + ')';
                                    } else {
                                        representated = '(' + value.representated.corporateName + ' (' + value.representated.identificationDocument + ')';
                                    }
                                }
                                destinatariType = $filter('translate')('global.literals.representative', {representated: representated});
                            }
                            var field = form.createField(
                                'receivers' + i,
                                'annexaRadioCheckboxRow',
                                'col-sm-12',
                                new AnnexaFormlyFieldSelectTemplateOptionsHtml(
                                    '',
                                    'value',
                                    'label',
                                    [{value: value.third.id, label: name, labelHtml: destinatariType}],
                                    false,
                                    'checkHTML',
                                    undefined,
                                    'labelHtml'
                                ),
                                data
                            );
                            modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup.push(field);
                            i++;
                        });
                        modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[0].templateOptions.label = $filter('translate')('global.literals.receivers');
                    }
                    modal.alerts = [];
                    var submitFunction = function () {
                        var dataRet = {thirds: [], documents: []};
                        if (receivers) {
                            angular.forEach(receivers, function (data, key) {
                                if (modal.annexaFormly.model.modal_body.thirds['receivers' + key + '_' + data.third.id] == true) {
                                    dataRet.thirds.push(data);
                                }
                            });
                        }
                        if (documents) {
                            angular.forEach(documents, function (data, key) {
                                if (modal.annexaFormly.model.modal_body.documents['documents' + key + '_' + data.id] == true) {
                                    dataRet.documents.push(data);
                                }
                            });
                        }
                        modal.close();
                        deferred.resolve(dataRet);
                    };

                    AnnexaFormlyFactory.showModal("modalEdit", modal, submitFunction, false, undefined, 'global.literals.accept');
                } catch (error) {
                    deferred.reject(error);
                }
                return deferred.promise;
            };

            factory.getNextDossierTransactions = function (transactionId) {
                var deferred = $q.defer();

                $http({
                    url: './api/tram/getNextDossierTransactions',
                    method: 'GET',
                    params: {transactionId: transactionId}
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };

            factory.isFinalTransaction = function (transaction) {
                if (ABMShapesFactory.isTransactionFinal(transaction) == true) {
                    var dlg = dialogs.confirm(
                        $filter('translate')('global.literals.confirmEndDossierTitle'),
                        $filter('translate')('global.literals.confirmEndDossierBody'),
                        {
                            animation: true,
                            backdrop: 'static',
                            keyboard: true,
                            size: '',
                            backdropClass: 'zMax',
                            windowClass: 'zMax2'
                        }
                    );

                    dlg.result.then(function (btn) {
                        factory.endDossier(transaction).then(function (data) {
                            $state.transitionTo('annexa.tram.pending');
                        }).catch(function (error) {
							if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
								var msg = $filter('translate')('global.tram.errors.processTemplate')+((error.data.message)?'<br/><br/>'+error.data.message:'');
								 var err = dialogs.error(
	                                $filter('translate')('DIALOGS_ERROR_MSG'),
	                                msg,
	                                {
	                                    animation: true,
	                                    backdrop: 'static',
	                                    keyboard: true,
	                                    size: ''
	                                }
	                            );
							}else if (error && error.data && error.data.message == 'No Required Custom Field Dossier Value data received in creation'){
								 var err = dialogs.error(
	                                $filter('translate')('DIALOGS_ERROR_MSG'),
	                                $filter('translate')('global.tram.errors.dossierCustomFields'),
	                                {
	                                    animation: true,
	                                    backdrop: 'static',
	                                    keyboard: true,
	                                    size: ''
	                                }
	                            );
							}else if (error && error.data && error.data.message == 'No Required Custom Field Value data received in creation'){
								 var err = dialogs.error(
	                                $filter('translate')('DIALOGS_ERROR_MSG'),
	                                $filter('translate')('global.tram.errors.dossierTransactionCustomFields'),
	                                {
	                                    animation: true,
	                                    backdrop: 'static',
	                                    keyboard: true,
	                                    size: ''
	                                }
	                            );
							}else if(error && error.data && error.data.message && error.data.message == 'Is not valid document from template not found'){
								var err = dialogs.error(
	                                $filter('translate')('DIALOGS_ERROR_MSG'),
	                                $filter('translate')('global.documents.definitiveNotificationNoValidError'),
	                                {
	                                    animation: true,
	                                    backdrop: 'static',
	                                    keyboard: true,
	                                    size: ''
	                                }
	                            );
							}else{
			                    var err = dialogs.error(
	                                $filter('translate')('DIALOGS_ERROR_MSG'),
	                                $filter('translate')(ErrorFactory.getErrorMessage('dossier', 'complete', error.data)),
	                                {
	                                    animation: true,
	                                    backdrop: 'static',
	                                    keyboard: true,
	                                    size: ''
	                                }
	                            );
							}
                        });
                    }, function (btn) {
                        var a = 0;
                    });
                } else {
                    var err = dialogs.error(
                        $filter('translate')('DIALOGS_ERROR_MSG'),
                        $filter('translate')('global.literals.errorCompletNotFinalTransaction'),
                        {
                            animation: true,
                            backdrop: 'static',
                            keyboard: true,
                            size: ''
                        }
                    );
                }
            };

            factory.isParallelUnguidedTransaction = function (transaction) {
                var isParallel = false;
                var endDateNull = 0;
                if (transaction && transaction.dossierTransactionGroups) {
                    angular.forEach(transaction.dossierTransactionGroups, function (value, key) {
                        if (value.dossierTransactions && endDateNull < 2) {
                            angular.forEach(value.dossierTransactions, function (value2, key2) {
                                if (endDateNull < 2) {
                                    if (!value2.endDate) {
                                        endDateNull++;
                                    }
                                }
                            });
                        }
                    });
                    if (endDateNull > 1) {
                        isParallel = true;
                    }
                }
                return isParallel;
            };

            factory.createTramResposibleModal = function (transaction, omission, reparation, free) {
        	   var data = {
                    row: true,
                    colClass: ' col-sm-12',
                    labelClass: ''
                };
                var modal = angular.copy(globalModals.createDossierTransactionPopup);
                modal.alerts = [];
                modal.annexaFormly = new AnnexaFormly();
                var fields = [];
                fields.push(modal.annexaFormly.createField('profile', 'annexaLoadUserAndComboButtonRow', 'col-sm-12',
                    new AnnexaFormlyLoadUserFieldTemplateOptions('text', 'global.literals.profile_handler', false, false, [], factory.searchProfiles, function () {
                        },
                        'Profile', 'global.literals.select', {dossier: factory.Dossier}, undefined, 'global.reg.literals.profileOrUserName', false, false, true)
                    , data)
                );
                modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {transactionNoGuiadaList: []};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.transaction = transaction;
                modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.omission = omission;
                modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.reparation = reparation;
                modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.free = free;
                modal.extra = {transaction: transaction, omissionOrReparation: ((omission || reparation)?true:false)};
                AnnexaFormlyFactory.showModalPromise("createTramResponsible", modal, factory.createTramResposible, false, false, 'global.literals.create').then(function (modalAux) {
                    modal.modal = modalAux;
                    modal.close = modalAux.element.scope().close;
                    modalAux.element.modal();
                    angular.element(".modal-title").focus();
                }).catch(function () {
                    var a = 0;
                });
            };

            factory.createTramResposible = function (component, profile, puser, transactionNoGuiadaList, completeTransaction) {
                var deferred = $q.defer();
                var self = this;
                if (self.annexaFormly.model.modal_body.transactionNoGuiadaList && self.annexaFormly.model.modal_body.transactionNoGuiadaList.length > 0) {
                    var currentLanguage = $translate.use();
                    var lang1 = Language.getByColumn('language1');
                    var lang2 = Language.getByColumn('language2');
                    var lang3 = Language.getByColumn('language3');
                    var trans = {};
                    $translate.use(lang1).then(function (translation) {
                        trans.tram1 = $translate.instant('global.literals.tramitation');
                        trans.puser1 = $translate.instant('global.tram.literals.pending_user');
                        $translate.use(lang2).then(function (translation) {
                            trans.tram2 = $translate.instant('global.literals.tramitation');
                            trans.puser2 = $translate.instant('global.tram.literals.pending_user');
                            $translate.use(lang3).then(function (translation) {
                                trans.tram3 = $translate.instant('global.literals.tramitation');
                                trans.puser3 = $translate.instant('global.tram.literals.pending_user');
                                $translate.use(currentLanguage);
                                var send = {};
                                send.dossierTransaction = JSOG.encode(self.extra.transaction);
                                if (self.annexaFormly.model.modal_body.transactionNoGuiadaList && self.annexaFormly.model.modal_body.transactionNoGuiadaList.length > 0) {
                                	var tngl = angular.copy(self.annexaFormly.model.modal_body.transactionNoGuiadaList);
                                    _.forEach( tngl, function(value){
                                    	if(value.selectedResponsible && value.selectedResponsible.id){
                                            value.selectedResponsible = {id:value.selectedResponsible.id};
                                        }
                                        if(value.expirationType) {
                                            value.expirationType = value.expirationType.id;
                                        }
                                    });
                                    send.transactionNoGuiadaList = JSOG.encode(tngl);
                                }
                                send.translations = trans;
                                send.omissionOrReparation = ((self.extra.omissionOrReparation)?true:false);
                                $http({
                                    url: './api/tram/dossierTransaction',
                                    method: 'POST',
                                    data: send
                                }).then(function (data) {
                                    deferred.resolve(data.data);
                                    self.close();
                                    $state.reload();
                                }).catch(function (error) {
                                    deferred.reject(error);
                                    self.alerts.push({msg: ErrorFactory.getErrorMessage('dossier', 'complete', error.data)});
                                })
                            }).catch(function (error) {
                                $translate.use(currentLanguage);
                                deferred.reject(error);
                            })
                        }).catch(function (error) {
                            $translate.use(currentLanguage);
                            deferred.reject(error);
                        })
                    }).catch(function (error) {
                        $translate.use(currentLanguage);
                        deferred.reject(error);
                    })
                } else {
                    self.alerts.push({msg: 'global.tram.errors.noTransactions'});
                    deferred.reject('global.tram.errors.noTransactions');
                }
                return deferred.promise;
            };

            factory.sendProposalDossierTransaction = function (self) {
                var deferred = $q.defer();
                if (self.annexaFormly.model.modal_body.transactionNoGuiadaList && self.annexaFormly.model.modal_body.transactionNoGuiadaList.length > 0) {
                    var currentLanguage = $translate.use();
                    var lang1 = Language.getByColumn('language1');
                    var lang2 = Language.getByColumn('language2');
                    var lang3 = Language.getByColumn('language3');
                    var trans = {};
                    $translate.use(lang1).then(function (translation) {
                        trans.tram1 = $translate.instant('global.literals.tramitation');
                        trans.puser1 = $translate.instant('global.tram.literals.pending_user');
                        $translate.use(lang2).then(function (translation) {
                            trans.tram2 = $translate.instant('global.literals.tramitation');
                            trans.puser2 = $translate.instant('global.tram.literals.pending_user');
                            $translate.use(lang3).then(function (translation) {
                                trans.tram3 = $translate.instant('global.literals.tramitation');
                                trans.puser3 = $translate.instant('global.tram.literals.pending_user');
                                $translate.use(currentLanguage);
                                var send = {};
                                send.dossierTransaction = JSOG.encode(self.extra.transaction);
                                if (self.annexaFormly.model.modal_body.transactionNoGuiadaList && self.annexaFormly.model.modal_body.transactionNoGuiadaList.length > 0) {
                                    var tngl = angular.copy(self.annexaFormly.model.modal_body.transactionNoGuiadaList);
                                	_.forEach(tngl, function(value){
                                    	if(value.selectedResponsible && value.selectedResponsible.id){
                                            value.selectedResponsible = {id:value.selectedResponsible.id};
                                        }
                                        if(value.expirationType) {
                                            value.expirationType = value.expirationType.id;
                                        }
                                        if(value.expirationType) {
                                        	value.expirationType = value.expirationType.id;
                                        }
                                    });
                                    send.transactionNoGuiadaList = JSOG.encode(tngl);
                                }
                                send.proposal = {id:self.extra.proposal.id};
                                
                                $http({
                                    url: './api/tram/sendProposalDossierTransaction',
                                    method: 'POST',
                                    data: send
                                }).then(function (data) {
                                    deferred.resolve(data.data);
                                }).catch(function (error) {
                                    deferred.reject(error);
									if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
										self.alerts.push({msg: 'global.tram.errors.processTemplate', msgAux:((error.data.message)?error.data.message : undefined), show:function(alert){if(alert){alert.showAux = ((alert.showAux)?false:true)}}, showAux:false});
									}else if(error.data && error.data.message && error.data.message == 'Is not valid document from template not found'){
										self.alerts.push({msg: 'global.documents.definitiveNotificationNoValidError'});
									}else if(error.data && error.data.message && error.data.message == 'error-requestCompleteDossier-is-in-restictive-transaction not found'){
										self.alerts.push({msg: 'global.tram.errors.inRestrictiveTransaction'});
									}else{
					                	self.alerts.push({msg: ErrorFactory.getErrorMessage('proposal', 'send', error.data)});
									}
                                })
                            }).catch(function (error) {
                                $translate.use(currentLanguage);
                                deferred.reject(error);
                            })
                        }).catch(function (error) {
                            $translate.use(currentLanguage);
                            deferred.reject(error);
                        })
                    }).catch(function (error) {
                        $translate.use(currentLanguage);
                        deferred.reject(error);
                    })
                } else {
                    self.alerts.push({msg: 'global.tram.errors.noTransactions'});
                    deferred.reject('global.tram.errors.noTransactions');
                }
                return deferred.promise;
            };

            factory.sendGuidedProposalDossierTransaction = function (transaction, proposal, nextDossierTransactionInfo) {
                var deferred = $q.defer();
                var send = {};
                send.dossierTransaction = transaction;
                send.proposal = {id:proposal.id};
                send.nextDossierTransactionInfo = nextDossierTransactionInfo;

                $http({
                    url: './api/tram/sendGuidedProposalDossierTransaction',
                    method: 'POST',
                    data: JSOG.encode(send)
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };
            
            factory.sendInspectionProposalDossierTransaction = function (self) {
                var deferred = $q.defer();
                if (self.annexaFormly.model.modal_body.transactionNoGuiadaList && self.annexaFormly.model.modal_body.transactionNoGuiadaList.length > 0) {
                    var currentLanguage = $translate.use();
                    var lang1 = Language.getByColumn('language1');
                    var lang2 = Language.getByColumn('language2');
                    var lang3 = Language.getByColumn('language3');
                    var trans = {};
                    $translate.use(lang1).then(function (translation) {
                        trans.tram1 = $translate.instant('global.literals.tramitation');
                        trans.puser1 = $translate.instant('global.tram.literals.pending_user');
                        $translate.use(lang2).then(function (translation) {
                            trans.tram2 = $translate.instant('global.literals.tramitation');
                            trans.puser2 = $translate.instant('global.tram.literals.pending_user');
                            $translate.use(lang3).then(function (translation) {
                                trans.tram3 = $translate.instant('global.literals.tramitation');
                                trans.puser3 = $translate.instant('global.tram.literals.pending_user');
                                $translate.use(currentLanguage);
                                var send = {};
                                send.dossierTransaction = JSOG.encode(self.extra.transaction);
                                if (self.annexaFormly.model.modal_body.transactionNoGuiadaList && self.annexaFormly.model.modal_body.transactionNoGuiadaList.length > 0) {
                                	var tngl = angular.copy(self.annexaFormly.model.modal_body.transactionNoGuiadaList);
                                    _.forEach( tngl, function(value){
                                    	if(value.selectedResponsible && value.selectedResponsible.id){
                                            value.selectedResponsible = {id:value.selectedResponsible.id};
                                        }
                                        if(value.expirationType) {
                                        	value.expirationType = value.expirationType.id;
                                        }
                                    });
                                    send.transactionNoGuiadaList = JSOG.encode(tngl);
                                }
                                send.inspectionProposal = {id:self.extra.inspectionProposal.id};
                                
                                $http({
                                    url: './api/tram/sendInspectionProposalDossierTransaction',
                                    method: 'POST',
                                    data: send
                                }).then(function (data) {
                                    deferred.resolve(data.data);
                                }).catch(function (error) {
                                    deferred.reject(error);
                                    self.alerts.push({msg: ErrorFactory.getErrorMessage('proposal', 'send', error.data)});
                                })
                            }).catch(function (error) {
                                $translate.use(currentLanguage);
                                deferred.reject(error);
                            })
                        }).catch(function (error) {
                            $translate.use(currentLanguage);
                            deferred.reject(error);
                        })
                    }).catch(function (error) {
                        $translate.use(currentLanguage);
                        deferred.reject(error);
                    })
                } else {
                    self.alerts.push({msg: 'global.tram.errors.noTransactions'});
                    deferred.reject('global.tram.errors.noTransactions');
                }
                return deferred.promise;
            };

            factory.sendGuidedInspectionProposalDossierTransaction = function (transaction, inspectionProposal, nextDossierTransactionInfo) {
                var deferred = $q.defer();
                var send = {};
                send.dossierTransaction = transaction;
                send.inspectionProposal = {id:inspectionProposal.id};
                send.nextDossierTransactionInfo = nextDossierTransactionInfo;

                $http({
                    url: './api/tram/sendGuidedInspectionProposalDossierTransaction',
                    method: 'POST',
                    data: JSOG.encode(send)
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };

            factory.searchProfiles = function (value) {
                if (!value || !value.val) {
                    return [];
                } else if (value.val != '*' && value.val.length < 3) {
                    return [];
                } else {
                    if (value.val == '*') {
                        value.val = '';
                    }
                    return RestService.filterData('common', 'Profile', {}, { search: value.val, langColumn:Language.getActiveColumn() }, 0, 100, '', 3)
                        .then(function(data) {
                            var items = [];

                            if(data.data && data.data.content) {
                                _.forEach(JSOG.decode(data.data.content), function(item) {
                                    items.push({ id: item.id, value: item[Language.getActiveColumn()], profile: item});
                                })
                            }

                            return items;
                        }).catch(function () {
                            return [];
                        });
                }
            };

            factory.isResponsible = function () {
                var responsible = false;
                if(factory.Dossier && $rootScope.LoggedUser){
                    if(factory.Dossier.responsibleUser && factory.Dossier.responsibleUser.id == $rootScope.LoggedUser.id){
                        responsible = true;
                    }else if(factory.Dossier.dossierResponsibleProfiles && factory.Dossier.dossierResponsibleProfiles.length > 0 && $rootScope.LoggedUser.userProfiles){
                        angular.forEach(factory.Dossier.dossierResponsibleProfiles, function(value){
                            var pr = $linq($rootScope.LoggedUser.userProfiles).singleOrDefault(undefined, "x => x.profile.id == " + value.profile.id);
                            if(pr){
                                responsible = true;
                            }
                        });
                    }
                }
                return responsible;
            };
            
            factory.haveProcedureTramitationProfile = function () {
            	var tramitator = false;
            	if(factory.Dossier && factory.Dossier.procedure && $rootScope.LoggedUser &&
            			factory.Dossier.procedure.procedureTramitationProfiles && factory.Dossier.procedure.procedureTramitationProfiles.length > 0){
        			angular.forEach(factory.Dossier.procedure.procedureTramitationProfiles, function(value){
                        var pr = $linq($rootScope.LoggedUser.userProfiles).singleOrDefault(undefined, "x => x.profile.id == " + value.profile.id);
                        if(pr){
                        	tramitator = true;
                        }
                    });
            	}
            	return tramitator;
            }

            factory.reopenTramModal = function () {
                var data = {
                    row: true,
                    colClass: ' col-sm-12',
                    labelClass: ''
                };
                var modal = angular.copy(globalModals.createDossierTransactionPopup);
                modal.title = $filter('translate')('global.literals.reopenTram');
                modal.alerts = [];
                modal.annexaFormly = new AnnexaFormly();
                var fields = [];
                fields.push(modal.annexaFormly.createField('profile', 'annexaLoadUserAndComboButtonRow', 'col-sm-12',
                    new AnnexaFormlyLoadUserFieldTemplateOptions('text', 'global.literals.profile_handler', false, false, [], factory.searchProfiles, function () {
                        },
                        'Profile', 'global.literals.select', {dossier: factory.Dossier}, undefined, 'global.reg.literals.profileOrUserName', false, false, true)
                    , data)
                );
                modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {transactionNoGuiadaList: []};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                AnnexaFormlyFactory.showModalPromise("reopenTram", modal, factory.reopenTram, false, false, 'global.literals.reopen').then(function (modalAux) {
                    modal.modal = modalAux;
                    modal.close = modalAux.element.scope().close;
                    modalAux.element.modal();
                    angular.element(".modal-title").focus();
                }).catch(function () {
                    var a = 0;
                });
            };

            factory.reopenTram = function (component, profile, puser, transactionNoGuiadaList, completeTransaction) {
                var deferred = $q.defer();
                var self = this;
                if (self.annexaFormly.model.modal_body.transactionNoGuiadaList && self.annexaFormly.model.modal_body.transactionNoGuiadaList.length > 0) {
                    var currentLanguage = $translate.use();
                    var lang1 = Language.getByColumn('language1');
                    var lang2 = Language.getByColumn('language2');
                    var lang3 = Language.getByColumn('language3');
                    var trans = {};
                    $translate.use(lang1).then(function (translation) {
                        trans.tram1 = $translate.instant('global.literals.tramitation');
                        trans.puser1 = $translate.instant('global.tram.literals.pending_user');
                        $translate.use(lang2).then(function (translation) {
                            trans.tram2 = $translate.instant('global.literals.tramitation');
                            trans.puser2 = $translate.instant('global.tram.literals.pending_user');
                            $translate.use(lang3).then(function (translation) {
                                trans.tram3 = $translate.instant('global.literals.tramitation');
                                trans.puser3 = $translate.instant('global.tram.literals.pending_user');
                                $translate.use(currentLanguage);
                                var send = {};
                                send.dossier = {id:factory.Dossier.id};
                                if (self.annexaFormly.model.modal_body.transactionNoGuiadaList && self.annexaFormly.model.modal_body.transactionNoGuiadaList.length > 0) {
                                	_.forEach( self.annexaFormly.model.modal_body.transactionNoGuiadaList, function(value){
                                        if(value.expirationType){
                                            value.expirationType = value.expirationType.id;
                                        }
                                    });
                                    send.transactionNoGuiadaList = self.annexaFormly.model.modal_body.transactionNoGuiadaList;
                                }
                                send.translations = trans;
                                $http({
                                    url: './api/tram/dossier/reopen/'+factory.Dossier.id,
                                    method: 'POST',
                                    data: send
                                }).then(function (data) {
                                    deferred.resolve(data.data);
                                    self.close();
                                    $state.transitionTo('annexa.tram.pending');
                                }).catch(function (error) {
                                    deferred.reject(error);
                                    self.alerts.push({msg: ErrorFactory.getErrorMessage('dossier', 'reopen', error.data)});
                                })
                            }).catch(function (error) {
                                $translate.use(currentLanguage);
                                deferred.reject(error);
                            })
                        }).catch(function (error) {
                            $translate.use(currentLanguage);
                            deferred.reject(error);
                        })
                    }).catch(function (error) {
                        $translate.use(currentLanguage);
                        deferred.reject(error);
                    })
                } else {
                    self.alerts.push({msg: 'global.tram.errors.noTransactions'});
                    deferred.reject('global.tram.errors.noTransactions');
                }
                return deferred.promise;
            };
            var calculateDates = function(dates, transaction, newDate){
                var newDates = [];
                var startDate = new Date(transaction.startDate);
                var endDate = transaction.endDate;
                if(endDate == null){
                    endDate = newDate;
                }else{
                    endDate = new Date(endDate);
                }
                _.forEach(dates, function(date, i) {
                    if(startDate < date.startDate && endDate < date.startDate) {
                        newDates.push({startDate:startDate, endDate:endDate});
                        newDates.push(date);
                    }else if(startDate > date.endDate && endDate > date.endDate){
                        newDates.push(date);
                        if(i == (dates.length -1)) {
                            newDates.push({startDate: startDate, endDate: endDate});
                        }
                    }else if (startDate < date.startDate && endDate > date.endDate) {
                        newDates.push({startDate:startDate, endDate:endDate});
                    }else if(startDate >= date.startDate && endDate > date.endDate){
                        newDates.push({startDate:date.startDate, endDate:endDate});
                    }else if(startDate < date.startDate && endDate <= date.endDate){
                        newDates.push({startDate:startDate, endDate:date.endDate});
                    }else{
                        newDates.push(date);
                    }
                });
                return newDates;
            }
            factory.getDaysPendingUser = function(dossierTransactions, loggedUser){
                var daysPendingUser = 0;
                if(dossierTransactions && dossierTransactions.length > 0){
                    var pendigUserTransactions = $linq(dossierTransactions).where("x => x.pendingUser").orderBy("x => new Date(x.startDate)").toArray();
                    if(pendigUserTransactions && pendigUserTransactions.length > 0) {
                        var dates = [];
                        var newdate = new Date();
                        _.forEach(pendigUserTransactions, function(transaction){
                            if(dates.length == 0){
                                if(transaction.endDate == null) {
                                    dates.push({startDate: new Date(transaction.startDate), endDate: newdate})
                                }else{
                                    dates.push({startDate: new Date(transaction.startDate), endDate: new Date(transaction.endDate)})
                                }
                            }else{
                                dates = calculateDates(dates, transaction, newdate);
                            }
                        });
                        if(dates && dates.length > 0) {
                            _.forEach(dates, function(date){
                                daysPendingUser += HelperService.getWorkingDays(date.startDate, date.endDate, loggedUser.entity.holidays);
                            });
                        }
                    }
                }
                return daysPendingUser;
            }

            factory.updateDossierThird = function(id, dossierThird) {
                var deferred = $q.defer();

                $http({
                    url: './api/dossier_third/',
                    method: 'PUT',
                    data: JSOG.encode(dossierThird)
                }).then(function (data) {
                    deferred.resolve(JSOG.decode(data.data))
                }).catch(function(error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            }
            
            factory.reopenDossierTransaction = function (idTransaction) {
                var deferred = $q.defer();
                $http({
                    url: './api/tram/dossier_transaction/reopen',
                    method: 'POST',
                    params: {idDossierTransaction: idTransaction}
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };
            
            factory.updateDossierTransactionProcessProfile = function (idDossierTransactionProcessProfile, idProfile) {
                var deferred = $q.defer();
                $http({
                    url: './api/tram/dossierTransactionProcessProfile',
                    method: 'PUT',
                    params: {idDossierTransactionProcessProfile: idDossierTransactionProcessProfile, idProfile: idProfile}
                }).then(function (data) {
                	deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };          
            
            factory.exportDossier = function() {
                var modal = angular.copy(globalModals.exportDossierModal);

                modal.languageColumn = Language.getActiveColumn();
                
                modal.dossierToExport = [];
                modal.eniExp = false;
                
                modal.removeDossier = function ($index) {
                    modal.dossierToExport.splice($index, 1);
                }
                
                modal.searchedValue = undefined;
            	modal.placeholder = 'global.literals.placeholder_search_dossiers';
            	modal.processSearch = function (object) {
            		var subject = '';
            		if (object.subject != null){
            			subject = " (" + object.subject + ")";
            		}
            		return object.dossierNumber + subject;
                };
            	modal.searchFunc = function(val) {
                    var added = [];
                    if(modal.dossierToExport && modal.dossierToExport.length > 0){
                    	added = $linq(modal.dossierToExport).select("x => x.id").toArray();
                    }
                    var filter = { subject: val.val, dossierNumber: val.val };
                    var additionalFilter = {};
                    additionalFilter.loggedUser = $rootScope.LoggedUser.id;
                    additionalFilter.added = added;
                    additionalFilter.langColumn = Language.getActiveColumn();

                    return RestService.filterData('tram', 'Dossier', filter, additionalFilter, 0, 10, '', 3, 'box')
                        .then(function (data) {
                            var response = [];
                            _.forEach(data.data.content, function(value, key) {
                                response.push({
                                    id: value.id,
                                    object: JSOG.decode(value),
                                    value: modal.processSearch(JSOG.decode(value))
                                });
                            });
                            response = new SortedArray(response, 'value').sort();
                            return response;
                        });
                }
            	modal.typeaheadSelect = function (item) {
                    if (item && item.value.indexOf('</i>') > 0) {
                        item.value = item.value.substring(item.value.indexOf('</i>') + 4, item.value.length);
                    }
                }
            	modal.addText = 'global.literals.add';
            	modal.addedSelect = 'x => x.id';
            	modal.document = {};
            	modal.searchAdd = function () {
                    if(modal.searchedValue && modal.searchedValue.id && modal.searchedValue.id != -1) {
                        modal.document = {
                            id: modal.searchedValue.id,
                            code: modal.searchedValue.object.dossierNumber,
                            title: modal.searchedValue.object.subject
                        };

                        modal.dossierToExport.push(modal.document);
                        modal.searchedValue = null;
                    }
                }
            	
            	modal.completeAdd = function() {
                	var newContent = [];
                	if(modal.searchedValue && modal.searchedValue.object && Array.isArray(modal.searchedValue.object)){
                		_.forEach(modal.searchedValue.object, function(obj){
                			newContent.push(JSOG.decode(obj));
                		});
                	}else{
                		newContent.push(JSOG.decode(modal.searchedValue.object));
                	}
                	modal.document = {};
        			_.forEach(newContent, function(obj){
        				modal.document = {
                            id: obj.id,
                            code: obj.dossierNumber,
                            title: obj.subject
                        };
        				modal.dossierToExport.push(modal.document);
                	});
        			modal.searchedValue = null;
                }
            	
            	$rootScope.$on('annexaAdvancedSearchModalAdd', function (event, args) {
                    if(args.id == 'modalASDossiers') {
                    	var addedObjects = [];
                        var id = undefined;
                        _.forEach(args.selected, function (item) {
                    		if(id){
                        		id =id+','+item.id;
                        	}else{
                        		id = item.id;
                        	}
                        	addedObjects.push(item);
                        });
                        
                        if(addedObjects && addedObjects.length > 0){
                        	modal.searchedValue = { id: id, object: addedObjects };
                            modal.completeAdd();
                        }

                        args.modal.close();
                    }
                });
            	
            	modal.advancedSearch = function() {
            		var addFilter = [{ id: 'show_archived', type: 'checkbox', order: 9, label: 'global.archive.archiveds', userChoosen: true, callAux: true }];
                    AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalASDossiers', AnnexaBoxDossierFactory.getAdvancedSearchModal(modal.dossierToExport, addFilter));
                }
            	
                modal.submitLabel = 'global.literals.exportDossierBtn';
                modal.submitFunction = function() {
                    var self = this;
                    self.alerts.length = 0;
                    var send = {};
                    if (modal.dossierToExport) {
                        var dossiers = [];
                        angular.forEach(modal.dossierToExport, function (value) {
                            if (value) {
                            	dossiers.push({id:value.id});
                            }
                        });
                        dossiers = $linq(dossiers).orderBy("x => x.id").toArray();
                        send.dossiers = JSOG.encode(dossiers);
                    }
                    send.languageColumn = Language.getActiveColumn();
                    send.saveEniExp = modal.eniExp;
                    if(!dossiers || dossiers.length == 0){
                        var errorTranslate = {msg: ErrorFactory.getErrorMessage('dossier', 'foliate', {message: 'Not found documents'})};
                        self.alerts.push({msg: errorTranslate.msg});
                    }else {
                        $http({
                            url: './api/tram/exportDossier',
                            method: 'POST',
                            data: send,
                            headers: {
                                accept: 'application/zip'
                            },
                            responseType: 'arraybuffer',
                            cache: false
                        }).then(function (data, status, headers) {
                            var contentType = data.headers('content-type');
                            if (!contentType) {
                                contentType = 'application/zip';
                            }
                            var name = data.headers('content-disposition');
                            if (name) {
                                name = name.split(";");
                                if (name && name.length > 1) {
                                    name = name[1].trim().split("=");
                                    if (name && name.length > 1) {
                                        name = name[1];
                                        name = name.replace(/"/g, '');
                                    } else {
                                        name = "documents.zip";
                                    }
                                } else {
                                    name = "documents.zip";
                                }
                            } else {
                                name = "documents.zip";
                            }
                            var file = new Blob([data.data], {type: contentType});
                            if (window.navigator.msSaveOrOpenBlob) {
                                try {
                                    window.navigator.msSaveOrOpenBlob(file, name);
                                } catch (e) {
                                    console.log(e.message);
                                }
                            } else {

                                var linkElement = document.createElement('a');
                                try {
                                    var url = window.URL.createObjectURL(file);
                                    linkElement.setAttribute('href', url);
                                    linkElement.setAttribute("download", name);
                                    var clickEvent = new MouseEvent("click", {
                                        "view": window,
                                        "bubbles": true,
                                        "cancelable": false
                                    });
                                    linkElement.dispatchEvent(clickEvent);
                                } catch (ex) {
                                    console.log(ex);
                                }
                            }
                            self.close();
                        }).catch(function (error) {
                            var errorTranslate = {msg: ErrorFactory.getErrorMessage('dossier', 'foliate', error.data)};
                            self.alerts.push({msg: errorTranslate.msg});
                        });
                    }
                }
            	
                AnnexaModalFactory.showModal('exportDossierModal', modal);
            }
            
			factory.getProjects = function(instanceCode){
            	var deferred = $q.defer();
                $http({
                    url: './api/tram/projects/'+instanceCode,
                    method: 'GET'
                }).then(function (data) {
                	deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                })
                return deferred.promise;
            }

            factory.getBudgets = function(instanceCode, areaGes){
            	var deferred = $q.defer();
                $http({
                    url: './api/tram/budgets/'+instanceCode+((areaGes)?'/'+areaGes:''),
                    method: 'GET'
                }).then(function (data) {
                	deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                })
                return deferred.promise;
            }
            
            factory.getBudgetItems = function(accountingReference, instanceCode){
            	var deferred = $q.defer();
                $http({
                    url: './api/tram/budgetItems/'+instanceCode,
                    method: 'GET',
                    params: {accountingReference: accountingReference}
                }).then(function (data) {
                	deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                })
                return deferred.promise;
            }
            
            factory.createOperationInvoice = function(operationInvoice){
            	var deferred = $q.defer();
            	$http({
                    url: './api/tram/createOperationInvoice',
                    method: 'POST',
                    data: JSOG.encode(operationInvoice)
                }).then(function (data) {
                    deferred.resolve(JSOG.decode(data.data))
                }).catch(function(error) {
                    deferred.reject(error);
                })
            	return deferred.promise;
            }
            
            factory.getOperationInvoice = function(operationInvoiceId){
            	var deferred = $q.defer();
            	$http({
                    url: './api/tram/getOperationInvoice/'+operationInvoiceId,
                    method: 'GET'
                }).then(function (data) {
                    deferred.resolve(JSOG.decode(data.data))
                }).catch(function(error) {
                    deferred.reject(error);
                })
            	return deferred.promise;
            }
            
            factory.getManagementCenters = function(instanceCode){
            	var deferred = $q.defer();
                $http({
                    url: './api/tram/managementCenters/'+instanceCode,
                    method: 'GET'
                }).then(function (data) {
                	deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                })
                return deferred.promise;
            }
            
            factory.getAccountingParameters = function(){
            	var deferred = $q.defer();
                $http({
                    url: './api/tram/accountingParameters',
                    method: 'GET'
                }).then(function (data) {
                	deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                })
                return deferred.promise;
            }
            factory.getDossierTransactionByDossierId = function(ids) {
                var httpPromise = $http({
                    url: './api/tram/dossierTransactions/dossiersId/'+CommonService.getParameterList(ids),
                    method: 'GET'
                }).then(function(data) {
                	if(data && data.data){
                		return JSOG.decode(data.data);
                	}else{
                		return [];
                	}
                }).catch(function(error) {
                    console.log(error);
                    return error;
                });

                return httpPromise;
            }
            
            factory.newRelatedDossier = function(dossierId, dossierIds, relation){
                var deferrend = $q.defer();
                $http({
                    method: 'PUT',
                    url: './api/tram/' + dossierId + '/addRelatedDossier/'+CommonService.getParameterList(dossierIds)+'/'+relation,
                }).success(function(data, status) {
                    deferrend.resolve(JSOG.decode(data));
                }).error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            };
            
            factory.deleteRelatedDossier = function(dossierId, dossierAuxId){
                var deferrend = $q.defer();
                $http({
                    method: 'PUT',
                    url: './api/tram/' + dossierId + '/deleteRelatedDossier/'+dossierAuxId,
                }).success(function(data, status) {
                    deferrend.resolve(JSOG.decode(data));
                }).error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            };

            factory.getRelatedThirds = function(dossierId){
                var deferrend = $q.defer();
                $http({
                    method: 'GET',
                    url: './api/tram/' + dossierId + '/getRelatedThirds',
                }).success(function(data, status) {
                    deferrend.resolve(JSOG.decode(data));
                }).error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            };
            
            factory.createRelatedThird = function(dossierId, dossierThird){
                var deferrend = $q.defer();
                $http({
                    method: 'PUT',
                    url: './api/tram/' + dossierId + '/createRelatedThird',
                    data: JSOG.encode(dossierThird)
                }).success(function(data, status) {
                    deferrend.resolve(JSOG.decode(data));
                }).error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            };
            
            factory.deleteRelatedThird = function(dossierId, thirdId){
                var deferrend = $q.defer();
                $http({
                    method: 'PUT',
                    url: './api/tram/' + dossierId + '/deleteRelatedThird/'+thirdId
                }).success(function(data, status) {
                    deferrend.resolve(JSOG.decode(data));
                }).error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            };
            factory.getAllIds = function(searchType, filter, filterAux) {
                var httpPromise = $http({
                    url: './api/tram/archive/getAllIds/'+searchType,
                    method: 'POST',
                    data:JSOG.encode({dossier:filter, additionalFilter:filterAux})
                }).then(function(data) {
                	if(data && data.data){
                		return JSOG.decode(data.data);
                	}else{
                		return [];
                	}
                }).catch(function(error) {
                    console.log(error);
                    return error;
                });

                return httpPromise;
            }
            
            factory.getCounters = function(searchType, filter, filterAux, objectType) {
                var httpPromise = $http({
                    url: './api/tram/archive/getCounters/'+searchType+'/'+objectType,
                    method: 'POST',
                    data:JSOG.encode({dossier:filter, additionalFilter:filterAux})
                }).then(function(data) {
                	if(data && data.data){
                		return JSOG.decode(data.data);
                	}else{
                		return [];
                	}
                }).catch(function(error) {
                    console.log(error);
                    return error;
                });

                return httpPromise;
            }
            
            factory.archiveClose = function(ids) {
                var httpPromise = $http({
                    url: './api/tram/archive/close/'+CommonService.getParameterList(ids),
                    method: 'PUT'
                }).then(function(data) {
                	if(data && data.data){
                		return JSOG.decode(data.data);
                	}else{
                		return {};
                	}
                }).catch(function(error) {
                    console.log(error);
                    return error;
                });

                return httpPromise;
            }
            factory.archiveTransfer = function(ids) {
                var httpPromise = $http({
                    url: './api/tram/archive/transfer/'+CommonService.getParameterList(ids),
                    method: 'PUT'
                }).then(function(data) {
                	if(data && data.data){
                		return JSOG.decode(data.data);
                	}else{
                		return {};
                	}
                }).catch(function(error) {
                    console.log(error);
                    return error;
                });

                return httpPromise;
            }
            factory.archiveArchive = function(ids, reason) {
                var httpPromise = $http({
                    url: './api/tram/archive/archive/'+CommonService.getParameterList(ids),
                    method: 'PUT',
                    data: {reason: reason}
                }).then(function(data) {
                	if(data && data.data){
                		return JSOG.decode(data.data);
                	}else{
                		return {};
                	}
                }).catch(function(error) {
                    console.log(error);
                    return error;
                });

                return httpPromise;
            }
            factory.archiveReject = function(ids, reason) {
                var httpPromise = $http({
                    url: './api/tram/archive/reject/'+CommonService.getParameterList(ids),
                    method: 'PUT',
                    data: {reason: reason}
                }).then(function(data) {
                	if(data && data.data){
                		return JSOG.decode(data.data);
                	}else{
                		return {};
                	}
                }).catch(function(error) {
                    console.log(error);
                    return error;
                });

                return httpPromise;
            }
            factory.archiveDefinitive = function(ids) {
                var httpPromise = $http({
                    url: './api/tram/archive/archiveDefinitive/'+CommonService.getParameterList(ids),
                    method: 'PUT'
                }).then(function(data) {
                	if(data && data.data){
                		return JSOG.decode(data.data);
                	}else{
                		return {};
                	}
                }).catch(function(error) {
                    console.log(error);
                    return error;
                });

                return httpPromise;
            }
            factory.addAccessRight = function(){
            	if(factory.Dossier && factory.Dossier.id){
            		 var modal = angular.copy(globalModals.addAccessRights);
                     modal.annexaFormly.model = {};
                     modal.annexaFormly.model.modal_body = {};
                     modal.annexaFormly.options = {};
                     modal.annexaFormly.options.formState = { readOnly: false};
                     modal.annexaFormly.model.modal_body.accessRightType = "USER";
                     var submitFunction = function() {
                         var self = this;
                         self.alerts.length = 0;
                         var model = ((this.annexaFormly && this.annexaFormly.model && this.annexaFormly.model.modal_body)?this.annexaFormly.model.modal_body:{});
                         var send = [];
                         if(model.accessRightType){
                        	 var today = new Date();
                        	 var m = today.getMonth();
                        	 var d = today.getDate();
                        	 var y = today.getFullYear();
                        	 if(model.startDate > model.endDate || model.startDate < new Date(y,m,d)){
                        		 this.alerts.push({ msg: $filter('translate')('global.archive.accessRightDateError') });
                        	 }else{
                        		 var existObject = false;
                        		 if(model.accessRightType == 'USER'){
                        			 if(model.user && model.user.id){
                        				 existObject = true;
                        				 send.push({
                        					 startDate: model.startDate,
                        					 endDate:model.endDate,
                        					 user: {id:model.user.id},
                        					 dossier: {id:factory.Dossier.id}
                        				 });	                        		 
                        			 }
                        		 } else if(model.accessRightType == 'THIRD'){
                        			 if(model.third && model.third.id){
                        				 existObject = true;
                        				 send.push({
                        					 startDate: model.startDate,
                        					 endDate:model.endDate,
                        					 third: {id:model.third.id},
                        					 dossier: {id:factory.Dossier.id} 
                        				 });
                        			 }
                        		 } else if(model.accessRightType == 'PROFILE'){
                        			 if(model.profiles && model.profiles.length > 0){
                        				 _.forEach(model.profiles, function(profile){
                        					 existObject = true;
                        					 send.push({
                        						 startDate: model.startDate,
                        						 endDate:model.endDate,
                        						 profile: {id:profile},
                        						 dossier: {id:factory.Dossier.id} 
                        					 });	                        			 
                        				 });
                        			 }
                        		 }
                        		 if(existObject){
                        			 $http({
                                         url: './api/tram/archive/generateRightAccess',
                                         method: 'POST',
                                         data: send, 
										 headers:{"callFromAnnexa":true}
                                     }).then(function (data, status, headers) {
										 if(data){
											var addedRA = JSOG.decode(data.data);
											if(addedRA && addedRA.length > 0){
												var date = new Date();
												_.forEach(addedRA, function(ra){
													ra.finished = ((ra.endDate < date)?true:false); 
													ra.current = ((ra.startDate <= date && ra.endDate >= date)?true:false);
													ra.canModifyStatus =  (((ra.rightAccessStatus == 'REQUEST' && !ra.finished) || (ra.rightAccessStatus == 'ACCEPTED' && !ra.current && !ra.finished) || (ra.rightAccessStatus == 'ACCEPTED' && ra.current))?true:false);
													factory.rightAccesses.push(ra);
												});
											}
										 }
                                         self.close();
                                     }).catch(function (error) {
                                         self.alerts.push({msg:  $filter('translate')('global.archive.accessRightError')});
                                     });
                        		 }
                        	 }
                         }
                     }
                     AnnexaFormlyFactory.showModal('addAccessRightsModal', modal, submitFunction, false, undefined);
            	}
            };

			factory.acceptRightAccess = function(id) {
                var deferrend = $q.defer();
                var httpPromise = $http({
                    url: './api/tram/archive/acceptRightAccess/'+id,
                    method: 'PUT'
                }).then(function(data) {
                	if(data && data.data){
                		deferrend.resolve(JSOG.decode(data.data));
                	}else{
                		deferrend.resolve({});
                	}
                }).catch(function(error) {
                    console.log(error);
                    deferrend.reject(error);
                });

                return deferrend.promise;
            }
			factory.denyRightAccess = function(id) {
                var deferrend = $q.defer();
                var httpPromise = $http({
                    url: './api/tram/archive/denyRightAccess/'+id,
                    method: 'PUT'
                }).then(function(data) {
                	if(data && data.data){
                		deferrend.resolve(JSOG.decode(data.data));
                	}else{
                		deferrend.resolve({});
                	}
                }).catch(function(error) {
                    console.log(error);
                    deferrend.reject(error);
                });

                return deferrend.promise;
            }
			factory.deleteRightAccess = function(id) {
                var deferrend = $q.defer();
                var httpPromise = $http({
                    url: './api/tram/archive/deleteRightAccess/'+id,
                    method: 'PUT'
                }).then(function(data) {
                	deferrend.resolve(true);
                }).catch(function(error) {
                    console.log(error);
                    deferrend.reject(error);
                });

                return deferrend.promise;
            }
			factory.revokeRightAccess = function(id) {
                var deferrend = $q.defer();
                var httpPromise = $http({
                    url: './api/tram/archive/revokeRightAccess/'+id,
                    method: 'PUT'
                }).then(function(data) {
                	if(data && data.data){
                		deferrend.resolve(JSOG.decode(data.data));
                	}else{
                		deferrend.resolve({});
                	}
                }).catch(function(error) {
                    console.log(error);
                    deferrend.reject(error);
                });

                return deferrend.promise;
            }
			factory.viewRightAccess = function(item, state) {
				var modal = angular.copy(globalModals.editAccessRights);
				modal.data = angular.copy(item);
				modal.languageColumn = Language.getActiveColumn();
				modal.seeAudit = function(){
	                window.open(state.href('annexa.admin.audit_right_access', {"objectId": item.id }), '_blank');
				}
				modal.submitFunction = function(val, prop) {
                    var ra = angular.copy(modal.data);
					ra[prop] = val;
					$http({
	                    url: './api/tram/archive/updateRightAccess/'+item.id,
	                    method: 'PUT',
						data: ra 
	                }).then(function(data) {
	                	var aux = JSOG.decode(data.data);
						var date = new Date();
						item[prop] = aux[prop];
						item.finished = ((aux.endDate < date)?true:false); 
						item.current = ((aux.startDate <= date && aux.endDate >= date)?true:false);
						item.canModifyStatus =  (((aux.rightAccessStatus == 'REQUEST' && !aux.finished) || (aux.rightAccessStatus == 'ACCEPTED' && !aux.current && !aux.finished) || (aux.rightAccessStatus == 'ACCEPTED' && aux.current))?true:false);
	                }).catch(function(error) {
	                    console.log(error);
						if(error && error.data && error.data.message == 'end date after start date'){
							DialogsFactory.error($filter('translate')('global.archive.accessRightDateError2'));
						}else{
  							DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
						}
	                });
                }
                AnnexaModalFactory.showModal('editAccessRightsModal', modal);                 
            }

 			factory.getRightAccessRemainingDays = function (dossierId) {
				var deferrend = $q.defer();
                var httpPromise = $http({
                    url: './api/tram/archive/rightAccessRemainingDays/'+dossierId,
                    method: 'GET'
                }).then(function(data) {
                	if(data){
                		deferrend.resolve(data.data);
                	}else{
                		deferrend.reject(0);
                	}
                }).catch(function(error) {
                    console.log(error);
                    deferrend.reject(error);
                });

                return deferrend.promise;                
            };

			factory.updateMetadata = function(metadata){
	            var deferrend = $q.defer();
	            $http({
	                method: 'PUT',
	                url: './api/tramEMGDE/' + metadata.id + '/emgdeDossierMetadata',
					data: JSOG.encode(metadata)
	            }).success(function(data, status) {
	                deferrend.resolve(JSOG.decode(data));
	            }).error(function(msg,code) {
	                deferrend.reject(msg);
	            });
	            return deferrend.promise;
	        };
	
			factory.updateMetadataObj = function(metadataObj, metadataId, metadataType){
	            var deferrend = $q.defer();
	            $http({
	                method: 'POST',
	                url: './api/tramEMGDE/' + metadataId + '/'+metadataType,
					data: JSOG.encode(metadataObj)
	            }).success(function(data, status) {
	                deferrend.resolve(JSOG.decode(data));
	            }).error(function(msg,code) {
	                deferrend.reject(msg);
	            });
	            return deferrend.promise;
	        };
	
			factory.deleteMetadataObj = function(metadataObjId, metadataId, metadataType){
	            var deferrend = $q.defer();
	            $http({
	                method: 'DELETE',
	                url: './api/tramEMGDE/' + metadataId + '/'+metadataType+'/'+metadataObjId
	            }).success(function(data, status) {
	                deferrend.resolve(JSOG.decode(data));
	            }).error(function(msg,code) {
	                deferrend.reject(msg);
	            });
	            return deferrend.promise;
	        };

			factory.updateThirdEMGDE = function(dossierId){
	            $http({
	                method: 'GET',
	                url: './api/tramEMGDE/' + dossierId+'/emgdeDossierMetadataInteresteds'
	            }).success(function(data, status) {
					if(factory.emgdeDossierMetadata){
						if(factory.emgdeDossierMetadata.interesteds){
							factory.emgdeDossierMetadata.interesteds.length = 0;
						}else{
							factory.emgdeDossierMetadata.interesteds = [];
						}
						if(data && data.length > 0){
							var dataAux = JSOG.decode(data);
							_.forEach(dataAux, function(interested){
								factory.emgdeDossierMetadata.interesteds.push(interested);	
							});
						}
					}
	            }).error(function(msg,code) {
					var a = 0;
	            });
	        };
	        
	        factory.getAllDossierDocuments = function() {
	        	var documents = [];
	        	if(factory.Dossier && factory.Dossier.dossierTransactions) {
            		angular.forEach(factory.Dossier.dossierTransactions, function (dt) {
						if (dt.documents) {
							documents = documents.concat(dt.documents);
						}
					});
            	}
	        	return documents;
	        }

            return factory;
    }]);
